import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/login/Login.vue'

const routes = [
    {path: '/', name: 'Index', component: Login}, //Do not lazy load this
    {path: '/login', name: 'Login', component: Login }, //Do not lazy load this
    
    {path: '/dashboard', name: 'Dashboard',component: () => import('../views/pages/Dashboard')},

    {path: '/orders', name: 'PurchaseOrdersList',component: () => import('../views/pages/documents/purchase_orders/PurchaseOrdersList')},
    {path: '/invoices', name: 'InvoicesList',component: () => import('../views/pages/documents/invoices/InvoicesList')},
    {path: '/credit-notes', name: 'CreditNotesList',component: () => import('../views/pages/documents/credit_notes/CreditNotesList')},
    {path: '/statements/:status?/:reason?', name: 'Statements',component: () => import('../views/pages/documents/statements/Statements')},
    {path: '/archive/', name: 'Archive',component: () => import('../views/pages/documents/archive/Archive')},
    {path: '/products/update_prices/', name: 'UpdateProductPrices',component: () => import('../views/pages/products/UpdateProductPrices')},
    {path: '/users/', name: 'UserManagement',component: () => import('../views/pages/user_management/Users')},
    {path: '/orders-history/', name: 'OrdersHistory',component: () => import('../views/pages/OrdersHistory')},

    {path: '/order/:store_number/:till_number/:transaction_number/:document_type?/:document_id?', name: 'documents',component: () => import('../views/pages/documents/Documents')},

    {path: '/reset-password/:id/:token', name: 'ResetPassword',component: () => import('../views/login/ResetPassword.vue')},

    {path: '/access-denied', name: 'AccessDenied',component: () => import('../views/info/AccessDenied')},
    {path: '/:pathMatch(.*)*', name: 'NotFound',component: () => import('../views/info/NotFound')},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router